import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY, 
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, 
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL, 
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, 
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET, 
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID, 
//   appId: process.env.REACT_APP_APP_ID, 
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID 
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWomtZ-4TtRENp9L9EZ5iayDZ8hgoEQdY",
  authDomain: "zoomers-fudging-rt.firebaseapp.com",
  databaseURL: "https://zoomers-fudging-rt.firebaseio.com",
  projectId: "zoomers-fudging-rt",
  storageBucket: "zoomers-fudging-rt.appspot.com",
  messagingSenderId: "335910220609",
  appId: "1:335910220609:web:9a2dd3f8f5c7f368c927c0",
  measurementId: "G-1T8PPWL2ZH"
};

firebase.initializeApp( firebaseConfig );

const db = firebase.firestore();

// eslint-disable-next-line no-restricted-globals
if ( location.hostname === 'localhost' ) { 
  db.settings( { 
    host: 'localhost:8090', 
    ssl: false 
  } );
};

export { firebase, db as default };
